import { useLocation } from "@reach/router"
import Footer from "components/Footer"
import Header from "components/Header"
import gsap from "gsap"
import { usePreloader } from "library/Loader/PreloaderUtils"
import { useBackButton } from "library/Loader/TransitionUtils"
import Scroll from "library/Scroll"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import { pathnameMatches } from "library/functions"
import { useTrackPageReady } from "library/pageReady"
import { useRef } from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import colors from "styles/colors"
import Banner from "./Banner"
import Preloader from "./Preloader"

interface LayoutProps {
	children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
	useTrackPageReady()
	useBackButton()

	const main = useRef<HTMLDivElement>(null)

	const onLoad = (isScrolled: boolean) => {
		if (isScrolled) return

		gsap.fromTo(
			main.current,
			{
				y: "100vh",
			},
			{
				y: 0,
				duration: 1,
				ease: "power2.inOut",
				onComplete: () => {
					// reset so as not to interfere with pins
					gsap.set(main.current, { clearProps: "all" })
				},
			},
		)
	}

	usePreloader({
		only: "whenAtTop",
		callback: () => onLoad(false),
		duration: 1.4,
	})

	usePreloader({
		only: "whenScrolled",
		callback: () => onLoad(true),
		duration: 0,
	})

	const pathname = useLocation().pathname
	const hideLine = ["/blog"].some((x) => pathnameMatches(pathname, x))

	return (
		<>
			<Preloader />
			<GlobalStyle />
			<Scroll>
				<Banner />
				<Header />
				<Main ref={main}>
					{!hideLine && <Line />}
					{children}
					<Footer />
				</Main>
			</Scroll>
		</>
	)
}

const Main = styled.main`
	margin: 0 auto;

	/* this can't be on the body or html, so we get a special wrapper for it */
	max-width: 100%;
	overflow-x: hidden;
`

const Line = styled.div`
	height: 1px;
	background: ${colors.grey1};

	${fresponsive(css`
		width: 1360px;
		margin: 0 auto;
	`)}

	${ftablet(css`
		width: 944px;
	`)}

${fmobile(css`
		width: 335px;
		position: relative;
		z-index: -3;
	`)}
`

const globalCss = css`
	/* default text styles */
	html {
		color: ${colors.charcoal};
		background: ${colors.alpine};
	}

	* {
		/* need this so that fonts match figma */
		text-rendering: geometricprecision;
		-webkit-font-smoothing: antialiased;
	}

	/* fixes for visbug */
	vis-bug {
		position: fixed;
		z-index: var(--layer-1);
	}

	visbug-metatip,
	visbug-handles,
	visbug-ally {
		position: absolute;
		z-index: var(--layer-top);
	}

	/** restore default focus states for elements that need them */
	*:focus-visible {
		outline: 2px solid #00f8;
	}
`

const GlobalStyle = createGlobalStyle`${globalCss}`
