import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { ReactComponent as CloseSVG } from "images/SmallArrowRight.svg"
import { loader } from "library/Loader"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export default function Banner() {
	const [showBanner, setShowBanner] = useState(false)
	const wrapper = useRef<HTMLDivElement>(null)

	const banners: Queries.BannerQuery = useStaticQuery(graphql`
		query Banner {
			allContentfulAnnouncementBanner(
				sort: { messagePriority: DESC }
				filter: { id: { ne: "6a3c8dcc-8a35-5bcf-9fa6-765ad03680fd" } }
			) {
				edges {
					node {
						id
						bannerMessage {
							bannerMessage
						}
						showMessageAt
						hideMessageAt
						messagePriority
						linkUrl
						dismissable
					}
				}
			}
		}
	`)

	const bannerToShow = banners?.allContentfulAnnouncementBanner.edges
		.map((e) => e.node)
		.find(
			(b) =>
				// start date either undefined or is in the past
				(b.showMessageAt === null || new Date(b.showMessageAt) < new Date()) &&
				// end date is in the future
				b.hideMessageAt &&
				new Date(b.hideMessageAt) > new Date(),
		)

	loader.useEventListener("end", (name) => {
		if (name === "initial") {
			setShowBanner(true)
		}
	})

	const closeOpen = useAnimation(
		() => {
			if (!bannerToShow) return
			try {
				const bannerHeight =
					(wrapper.current?.getBoundingClientRect().height ?? 0) + 1
				const tl = gsap.timeline({
					paused: true,
					onComplete: () => {
						ScrollTrigger.refresh()
					},
					onReverseComplete: () => {
						ScrollTrigger.refresh()
					},
				})

				tl.fromTo(
					[wrapper.current],
					{
						marginBottom: 0,
						y: 0,
						ease: "power3.inOut",
					},
					{
						marginBottom: -bannerHeight,
						y: -bannerHeight,
						duration: 1,
						ease: "power3.inOut",
					},
					0,
				)
				tl.fromTo(
					"main",
					{
						duration: 1,
						ease: "power3.inOut",
					},
					{
						duration: 1,
						ease: "power3.inOut",
					},

					0,
				)

				return tl
			} catch (e) {
				console.error(e)
			}
		},
		[bannerToShow],
		{
			recreateOnResize: true,
		},
	)

	useEffect(() => {
		if (showBanner) closeOpen?.reverse()
		else closeOpen?.play()
	}, [showBanner, closeOpen])

	if (!bannerToShow?.bannerMessage?.bannerMessage) return null
	return (
		<UniversalLink to={bannerToShow.linkUrl ?? "/"}>
			<Wrapper
				onClick={() => {
					if (bannerToShow.dismissable) setShowBanner(false)
				}}
				ref={wrapper}
			>
				<Copy>{bannerToShow.bannerMessage.bannerMessage}</Copy>
				<CloseSVG />
			</Wrapper>
		</UniversalLink>
	)
}

const Wrapper = styled.div`
	background: ${colors.blue2};
	color: ${colors.pureWhite};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 101;
	position: relative;
	border-bottom: 1px solid ${colors.blue2};
	transition: 0.3s ease-out;
	transition-property: background, color;

	${fresponsive(css`
		gap: 16px;
		min-height: 48px;
		padding: 18px 20px;

		svg {
			display: block;
			width: 16px;
			height: 16px;
			flex-shrink: 0;
			transition: 0.3s ease-out;
			transition-property: rotate;

			path {
				transition: 0.3s ease-out;
				transition-property: stroke;
				stroke: ${colors.pureWhite};
			}
		}
	`)}

	&:hover {
		background: ${colors.pureWhite};
		color: ${colors.blue2};

		svg {
			rotate: -45deg;

			path {
				stroke: ${colors.blue2};
			}
		}
	}

	${ftablet(css`
		min-height: 64px;
		padding: 15px 20px;
	`)}

	${fmobile(css`
		min-height: 56px;
		padding: 18px 16px;
	`)}
`

const Copy = styled.p`
	${textStyles.p2};
	word-wrap: balance;

	${ftablet(css`
		${textStyles.p1};
	`)}

	${fmobile(css`
		${textStyles.p3};
	`)}

	
	* {
		display: inline;
	}
`
